import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <div className="p-4">
                    <h1 className="text-2xl font-bold mb-4">
                        Presentem el nou curs de preparació per a les proves d'accés a qualsevol
                        Policia Local de Catalunya!
                    </h1>

                    <p className="mb-4">
                        Un sistema innovador que et permetrà preparar els tres grans blocs clau per
                        a qualsevol Ajuntament:
                    </p>

                    <ul className="list-disc list-inside mb-4">
                        <li>
                            <strong>Temari</strong>
                        </li>
                        <li>
                            <strong>Cultura General</strong>
                        </li>
                        <li>
                            <strong>Actualitat</strong>
                        </li>
                    </ul>

                    <h2 className="text-xl font-bold mb-2">
                        Ara amb classes en directe setmanals!
                    </h2>
                    <p className="mb-4">
                        Cada dimecres, de 17:00 a 19:00 h. A més, les sessions quedaran gravades
                        perquè les puguis veure quan vulguis.
                    </p>

                    <h2 className="text-xl font-bold mb-2">
                        Inclou les lleis i temes essencials per a les oposicions
                    </h2>
                    <ul className="list-disc list-inside mb-4">
                        <li>Constitució Espanyola</li>
                        <li>Estatut d'Autonomia de Catalunya</li>
                        <li>Procediment Administratiu</li>
                        <li>Llei 4/2003 d'ordenació del Sistema de Seguretat de Catalunya</li>
                        <li>Llei Orgànica 4/2015 de protecció de la seguretat ciutadana</li>
                        <li>Llei 16/1991 de les Policies Locals</li>
                        <li>Llei Orgànica 2/1986 de Forces i Cossos de Seguretat</li>
                        <li>Text Refós de l'Estatut Bàsic de l'Empleat Públic (TREBEP)</li>
                        <li>Unió Europea</li>
                        <li>Habeas Corpus</li>
                        <li>Codi Penal</li>
                        <li>Vehicles de mobilitat personal</li>
                        <li>Història de Catalunya, Espanya i Universal</li>
                        <li>Llei 1/2004 de protecció integral contra la violència de gènere</li>
                        <li>
                            Trànsit, reglament d'armes i tinença d'animals potencialment perillosos
                        </li>
                        <li>Llei del Menor 5/2000</li>
                        <li>Atestat policial</li>
                        <li>Reglament General de Conductors i de Circulació</li>
                        <li>
                            Llei 17/2020 sobre els drets de les dones a erradicar la violència
                            masclista
                        </li>
                    </ul>

                    <h2 className="text-xl font-bold mb-2">De cada tema trobaràs:</h2>
                    <ul className="list-disc list-inside mb-4">
                        <li>Vídeos explicatius amb PowerPoint destacant els punts clau</li>
                        <li>Resums de la legislació</li>
                        <li>Text íntegre de les lleis</li>
                        <li>Tests específics per practicar</li>
                    </ul>

                    <h2 className="text-xl font-bold mb-2">Cultura general</h2>
                    <p className="mb-4">
                        Aquesta part és crucial, ja que a moltes oposicions representa el 50% de la
                        nota. En aquest curs, trobaràs vídeos explicatius i tests per dominar temes
                        com ciències, geografia, literatura, astronomia, inventors, i molt més.
                    </p>

                    <h2 className="text-xl font-bold mb-2">Actualitat</h2>
                    <p className="mb-4">
                        Aquest és un dels pilars del curs, amb continguts que s'actualitzen cada
                        setmana. T'informaràs de les notícies més rellevants i podràs practicar amb
                        tests de l'assignatura.
                    </p>

                    <p className="font-bold mb-4">
                        Amb aquest curs adquiriràs les eines necessàries per afrontar amb èxit les
                        oposicions de Policia Local, amb totes les garanties.
                    </p>

                    <p className="mb-4">
                        <strong>Accés a la plataforma del curs:</strong> 60 dies.
                    </p>
                </div>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/fUDCF-27Xg8"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    ></iframe>
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't per a les oposicions de Policia Local amb el nostre curs innovador i classes en directe setmanals!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );

    /*return (
        <ProductTemplate item={item}>            
            <div className="container lg:px-0 py-20">
                <Title>El Curso</Title>
                <div className="mt-10">
                    <Text className="text-justify">
                        Et presentem el nou curs per preparar les proves d'accés a qualsevol Policia
                        Local de Catalunya.
                        <br />
                        Un sistema innovador on podràs preparar els tres grans conceptes importants
                        per a qualsevol Ajuntament:
                        <br />
                        <br />
                        <strong>- Temari</strong>
                        <br />
                        <strong>- Cultura General</strong>
                        <br />
                        <strong>- Actualitat</strong>
                        <br />
                        <br />
                        Inclou les principals lleis i temes que s'inclouen en les oposicions.
                        <br />
                        <br />
                        - Constitució Espanyola
                        <br />
                        - Estatut d'Autonomia de Catalunya
                        <br />
                        - Procediment Administratiu
                        <br />
                        - Llei 4/2003 d'ordenació del Sistema de Seguretat de Catalunya
                        <br />
                        - Llei Orgànica 4/2015 de protecció de la seguretat ciutadana
                        <br />
                        - Llei 16/1991 de les Policies Locals
                        <br />
                        - Llei Orgànica 2/1986 de Forces i Cossos de Seguretat
                        <br />
                        - Text Refós de l'Estatut Bàsic de l'Empleat Públic (TREBEP)
                        <br />
                        - Unió Europea
                        <br />
                        - Habeas Corpus
                        <br />
                        - Codi penal
                        <br />
                        - Vehícles de mobilitat personal
                        <br />
                        - Història de Catalunya
                        <br />
                        - Història d' Espanya
                        <br />
                        - Història Universal
                        <br />
                        - Llei 1/2004 de protecció integral contra la violència de génere.
                        <br />
                        - Trànsit
                        <br />
                        - Reglament d' armes
                        <br />
                        - Tinença d' animals potencialment perillosos
                        <br />
                        - Llei del menor 5/2000
                        <br />
                        - L'atestat policial
                        <br />
                        - Reglament General de conductors
                        <br />
                        - Reglament General de circulació
                        <br />
                        - Llei 17/2020 del dret des les dones a erradicar la violència masclista
                        <br />
                        <br />
                        Un curs interactiu i en constant evolució on poder afegir temes o lleis de
                        nova incorporació.
                        <br />
                        <br />
                        De cada tema trobaràs:
                        <br />
                        · Vídeo amb PowerPoint explicatiu amb el més destacat de la llei
                        <br />
                        · Resum de la llei
                        <br />
                        · Llei sencera
                        <br />
                        · Test específic de la llei
                        <br />
                        <br />
                        Sobre la cultura general: Sabem la importància de preparar aquesta part atès
                        que a la majoria d'oposicions és el 50% de la nota, per tant, és bàsic tenir
                        al dia coneixements bàsics sobre ciències, geografia, literatura,
                        astronomia, inventors... També trobaràs els temes dividits en vídeos
                        explicatius i test per practicar!
                        <br />
                        <br />
                        Un dels pilars bàsics es l'actualitat, una assignatura que s'actualitzarà
                        setmanalment i on trobaràs les notícies més destacades i una zona de test
                        per practicar.
                        <br />
                        <br />
                        Amb aquest curs assoliràs els conceptes primordials per afrontar amb totes
                        les garanties d'èxit una oposició de Policia Local.
                        <br />
                        <br />
                        Duració de l'accés a la plataforma del curs: 4 mesos.
                        <br />
                        <br />
                        <strong>PAGAMENT ÚNIC: 99,99 €.</strong>
                    </Text>
                </div>
            </div>
            
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>

                    <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/neeymPHhHvc"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        ></iframe>
                    </div>
                </div>
            </div>
        </ProductTemplate>
    );*/
};

export default Product;
